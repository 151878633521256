<ng-container *ngrxLet="data$ as data">
  <mat-toolbar
    *ngIf="data.options?.filtersToolbar && !data.options?.rocksQuickFilters"
    color="accent"
    id="filters-toolbar"
    [class.is-meeting]="isMeeting"
    [ngClass]="(filterService.sideNavExpanded$ | async) ? 'expanded' : 'collapsed'"
    [class.is-meeting-rocks]="isMeeting && data.options?.rockStatusDropdown"
    [class.show-responsive-filters]="data.showResponsiveFilters"
    [class.hide-responsive-filters]="!data.showResponsiveFilters"
    [class.terrafy-filters-toolbar]="data.terrafy"
    [class.mat-elevation-z2]="!data.terrafy">
    <h3 attr.data-cy="filters-toolbar_title-{{ data.options?.title }}" *ngIf="data.options?.showTitle">
      {{ data.options?.title }}
    </h3>
    <div class="all-filters" *ngIf="!data.options?.showTitle">
      <div class="static-filters">
        <h3 class="app-name" *ngIf="data.options?.toolbarDescriptor?.length">
          {{ data.options.toolbarDescriptor }}
        </h3>
        <div
          *ngIf="data.options?.teamDropdown && !stateService.isLiteUser"
          class="team-dropdown terrafy-select-quick-filter">
          <label>Team:</label>
          <mat-form-field class="select-team">
            <!-- do not change...using team instead of _id for a reason -->
            <mat-select
              #teamSelect
              disableOptionCentering
              panelClass="team-select-panel"
              data-cy="filters-toolbar_team-select"
              [value]="data.selectedTeam"
              (selectionChange)="selectTeam($event.value)"
              (openedChange)="onTeamDropdownOpened($event)"
              [compareWith]="teamsAreSame"
              [disabled]="data.isFetchingData">
              <mat-select-trigger *ngIf="data.terrafy" class="terrafy-select-quick-filter__trigger">
                Team:
                <span>
                  {{ data.selectedTeam.name }}
                </span>
                <terra-icon size="16" [icon]="teamSelect.panelOpen ? 'caret-up' : 'caret-down'" />
              </mat-select-trigger>
              <div class="team-search-wrapper">
                <ninety-team-search data-cy="filters-toolbar_team-search" (searchChanged)="onSearchChanged($event)" />
                <span
                  class="no-search-results"
                  *ngIf="
                    !data.filteredTeams ||
                    data.filteredTeams.length === 0 ||
                    (data.filteredTeams.length === 1 && data.filteredTeams[0].dontShowInResults)
                  ">
                  No search results found. Please check your spelling or try a different search.
                </span>
              </div>
              <mat-option
                *ngFor="let team of data.filteredTeams; trackBy: trackById"
                attr.data-cy="filters-toolbar_option-{{ team.name }}"
                [value]="team"
                [disabled]="data.options.filterOutProjectTeams && team.project"
                [style.display]="data.searchTerm && team.dontShowInResults ? 'none' : 'block'">
                <span class="team-name">{{ team.name }}</span>
                <span *ngIf="data.options.filterOutProjectTeams && team.project">(Project)</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div
          *ngIf="data.options?.personDropdown && stateService.isManagerOrAbove"
          class="person-dropdown terrafy-select-quick-filter">
          <label>Person:</label>
          <mat-form-field>
            <mat-select
              data-cy="filters-toolbar_select-person"
              #personSelect
              [(value)]="data.selectedUserId"
              disableOptionCentering
              (selectionChange)="filterService.setUserId($event.value)">
              <mat-select-trigger *ngIf="data.terrafy" class="terrafy-select-quick-filter__trigger">
                Person:
                <span>
                  {{
                    data.selectedUserId === 'all'
                      ? 'All'
                      : (getUserById(userService.allUsersAndImplementers, data.selectedUserId)?.metadata.name
                        | displayFullName)
                  }}
                </span>
                <terra-icon size="16" [icon]="personSelect.panelOpen ? 'caret-up' : 'caret-down'" />
              </mat-select-trigger>
              <mat-option data-cy="filters-toolbar_all-option" value="all">
                <div class="avatar-icon">
                  <terra-icon icon="users" />
                </div>
                <span class="user-name">All</span>
              </mat-option>
              <mat-option
                attr.data-cy="filters-toolbar_user-name-{{ user.metadata.name | displayFullName }}"
                *ngFor="
                  let user of userService.allUsersAndImplementers
                    | filterUsersByRole : RoleCode.observer : true
                    | sortByUserName;
                  trackBy: trackById
                "
                [value]="user._id">
                <ng-container *ngIf="personSelect.panelOpen">
                  <terra-avatar
                    [src]="user?.metadata?.picture?.url"
                    [initials]="user | userInitials"
                    [inactive]="!user.active" />
                </ng-container>
                <span class="user-name">{{ user.metadata.name | displayFullName }}</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div *ngIf="data.options.ownerDropdown" class="owner-dropdown terrafy-select-quick-filter">
          <label>Owner:</label>
          <mat-form-field>
            <mat-select
              #ownerSelect
              disableOptionCentering
              [(value)]="data.selectedUserId"
              (selectionChange)="filterService.setOwnerId($event.value)">
              <mat-select-trigger *ngIf="data.terrafy" class="terrafy-select-quick-filter__trigger">
                Owner:
                <span>
                  {{
                    data.selectedOwnerId === 'all'
                      ? 'All'
                      : (getUserById(userService.allUsersAndImplementers, data.selectedOwnerId)?.metadata.name
                        | displayFullName)
                  }}
                </span>
                <terra-icon size="16" [icon]="ownerSelect.panelOpen ? 'caret-up' : 'caret-down'" />
              </mat-select-trigger>
              <mat-option value="all">
                <div class="avatar-icon">
                  <terra-icon icon="users" />
                </div>
                <span class="user-name">All</span>
              </mat-option>
              <mat-option *ngFor="let user of userService.allUsersAndImplementers | sortByUserName" [value]="user._id">
                <terra-avatar [src]="user.metadata?.picture?.url" [initials]="user | userInitials" />
                <span class="user-name">{{ user.metadata.name | displayFullName }}</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="fill-remaining-space"></div>

        <button mat-button class="filters-btn mat-icon-button" (click)="filterService.onToggleFiltersToolbar()">
          <terra-icon icon="list" />
        </button>
      </div>

      <div class="responsive-filters">
        <div *ngIf="data.options?.userStatusDropdown" class="user-status-dropdown terrafy-select-quick-filter">
          <label>Users:</label>
          <mat-form-field>
            <mat-select
              #userSelect
              disableOptionCentering
              data-cy="filters-toolbar_user-status-dropdown"
              [(value)]="data.selectedUserStatusId"
              (selectionChange)="filterService.setUserStatusId($event.value)">
              <mat-select-trigger *ngIf="data.terrafy" class="terrafy-select-quick-filter__trigger">
                Users:
                <span>
                  {{
                    data.selectedUserStatusId === DirectoryUserStatus.all
                      ? 'All'
                      : data.selectedUserStatusId === DirectoryUserStatus.paid
                      ? 'Paid Users'
                      : data.selectedUserStatusId === DirectoryUserStatus.invited
                      ? 'Invited Users'
                      : data.selectedUserStatusId === DirectoryUserStatus.pending
                      ? 'Pending Users'
                      : data.selectedUserStatusId === DirectoryUserStatus.notInvited
                      ? 'Not Invited'
                      : ''
                  }}
                </span>
                <terra-icon size="16" [icon]="userSelect.panelOpen ? 'caret-up' : 'caret-down'" />
              </mat-select-trigger>
              <mat-option data-cy="filters-toolbar_option-all" [value]="DirectoryUserStatus.all">All</mat-option>
              <mat-option data-cy="filters-toolbar_option-paid" [value]="DirectoryUserStatus.paid"
                >Paid Users</mat-option
              >
              <mat-option data-cy="filters-toolbar_option-invited" [value]="DirectoryUserStatus.invited"
                >Invited Users</mat-option
              >
              <mat-option data-cy="filters-toolbar_option-pending" [value]="DirectoryUserStatus.pending"
                >Pending Users</mat-option
              >
              <mat-option data-cy="filters-toolbar_option-not-invited" [value]="DirectoryUserStatus.notInvited"
                >Not Invited</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>

        <terra-switch
          *ngIf="data.options?.vtoToggle && vtoService.teamVto && stateService.isManagerOrAbove && data.isSlt"
          class="vto-cascade-toggle"
          [class._terra-migration-approved-override--filters-toolbar]="!data.terrafy"
          [(ngModel)]="vtoService.teamVto.isSharing"
          (change)="vtoService.update(vtoService.teamVto._id, { isSharing: $event }, true).subscribe()"
          matTooltip="Share {{ data.language.vto.item }} with All Teams"
          matTooltipPosition="above"
          matTooltipClass="ninety-tooltip">
          Share {{ data.language.vto.item }} with All Teams
        </terra-switch>

        <terra-switch
          *ngIf="data.options?.vtoToggle && vtoService.teamVto && !data.isSlt && vtoService.sltVto?.isSharing"
          class="vto-cascade-toggle"
          [class._terra-migration-approved-override--filters-toolbar]="!data.terrafy"
          [(ngModel)]="vtoService.viewingSltVto"
          (change)="vtoService.toggleSltVto()"
          [disabled]="data.isFetchingData"
          matTooltip="View Leadership {{ data.language.vto.item }}"
          matTooltipPosition="above"
          matTooltipClass="ninety-tooltip">
          View Leadership {{ data.language.vto.item }}
        </terra-switch>

        <terra-switch
          *ngIf="data.options?.showCompletedConversations"
          data-cy="filters-toolbar_show-completed-conversations-toggle"
          class="completed-conversations-toggle"
          [class._terra-migration-approved-override--filters-toolbar]="!data.terrafy"
          [ngModel]="showCompletedConversations$ | async"
          (change)="filterService.onShowCompletedConversations()"
          [disabled]="data.isFetchingData"
          matTooltip="Show completed {{ data.language.feedback.items }}"
          matTooltipPosition="above"
          matTooltipClass="ninety-tooltip">
          Show completed
        </terra-switch>

        <div class="archive-copy">
          <button
            data-cy="filters-toolbar_archive-copy-menu-button"
            *ngIf="data.options?.archiveCopy && vtoService.teamVto"
            mat-button
            [matMenuTriggerFor]="archiveCopyMenu"
            [disabled]="data.isFetchingData">
            <terra-icon icon="archive" />
            Archive
          </button>
        </div>

        <mat-menu #archiveCopyMenu="matMenu">
          <button
            data-cy="filters-toolbar_archive-copy-menu-item"
            mat-menu-item
            *ngIf="stateService.isManagerOrAbove"
            [class.orange]="!data.isFetchingData && !data.archivedVto && !vtoService.viewingSltVto"
            (click)="filterService.archiveCopy$.next(null)"
            [disabled]="data.isFetchingData || !!data.archivedVto || vtoService.viewingSltVto">
            <terra-icon icon="archive" />
            <span>Archive a copy of this {{ data.language.vto.item }}</span>
          </button>
          <button
            data-cy="filters-toolbar_view-archived-menu-item"
            mat-menu-item
            [matMenuTriggerFor]="archivedVtos"
            [disabled]="!vtoService.teamVto?.archivedVtoIds?.length">
            View Archived {{ data.language.vto.items }}
          </button>
        </mat-menu>

        <mat-menu #archivedVtos="matMenu">
          <button
            attr.data-cy="filters-toolbar_archived-vision-{{
              helperService.getCreatedDate(archivedObjectId) | date : 'mediumDate' : undefined : stateService.locale
            }}"
            mat-menu-item
            *ngFor="let archivedObjectId of vtoService.teamVto?.archivedVtoIds"
            class="archive-btn"
            (click)="vtoService.getArchivedVto(archivedObjectId)"
            [class.selected-vto]="data.archivedVto?._id === archivedObjectId"
            matTooltip="View this Archived {{ data.language.vto.item }}"
            matTooltipPosition="above"
            matTooltipClass="ninety-tooltip">
            <terra-icon icon="show" *ngIf="data.archivedVto?._id !== archivedObjectId" />
            <terra-icon icon="check" class="orange" *ngIf="data.archivedVto?._id === archivedObjectId" />
            <span class="archived-date">
              {{
                helperService.getCreatedDate(archivedObjectId) | date : 'mediumDate' : undefined : stateService.locale
              }}
            </span>
            <span class="fill-remaining-space"></span>
            <terra-icon
              icon="delete"
              class="red right-space-0"
              ninetyStopPropagation
              *ngIf="stateService.isManagerOrAbove"
              (click)="vtoService.removeArchivedVto(archivedObjectId)"
              matTooltip="Delete this Archived {{ data.language.vto.item }}"
              matTooltipPosition="above"
              matTooltipClass="ninety-tooltip" />
          </button>
        </mat-menu>

        <button mat-button *ngIf="data.archivedVto" (click)="vtoService.viewTeamVto()" [disabled]="data.isFetchingData">
          <terra-icon icon="undo" />
          <span>View your team {{ data.language.vto.item }}</span>
        </button>

        <div *ngIf="data.options?.rockStatusDropdown" class="filter-drop rock-statuses terrafy-select-quick-filter">
          <label>{{ data.language.rock.item }} Status:</label>
          <mat-form-field>
            <mat-select
              #statusSelect
              disableOptionCentering
              data-cy="filters-toolbar_rock-statuses-dropdown"
              [(value)]="data.selectedRockFilter"
              (selectionChange)="filterService.setRockStatusFilter($event.value)">
              <mat-select-trigger *ngIf="data.terrafy" class="terrafy-select-quick-filter__trigger">
                Status:
                <span>
                  {{ data.selectedRockFilter }}
                </span>
                <terra-icon size="16" [icon]="statusSelect.panelOpen ? 'caret-up' : 'caret-down'" />
              </mat-select-trigger>
              <mat-option value="all">All</mat-option>
              <mat-option
                attr.data-cy="filters_toolbar-{{ rockStatus.label }}"
                *ngFor="let rockStatus of rockStatusService.statuses"
                [value]="rockStatus.code">
                <terra-icon [icon]="rockStatus.icon" variant="fill" [ngClass]="rockStatus.class" class="status-icon" />
                <span>{{ rockStatus.label }}</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="data.options?.masteryStatusDropdown" class="filter-drop terrafy-select-quick-filter">
          <label>Status:</label>
          <mat-form-field>
            <mat-select
              disableOptionCentering
              #masteryStatus
              data-cy="filter-toolbar_mastery-status_dropdown"
              [(value)]="data.selectedMasteryStatus"
              (selectionChange)="filterService.setMasteryStatusFilter($event.value)"
              [disabled]="data.isFetchingData">
              <mat-select-trigger *ngIf="data.terrafy" class="terrafy-select-quick-filter__trigger">
                Status:
                <span>
                  {{
                    data.selectedMasteryStatus === MasteryFilterStatus.All
                      ? 'All'
                      : data.selectedMasteryStatus === MasteryFilterStatus.Mastered
                      ? 'Mastered'
                      : 'Not Mastered'
                  }}
                </span>
                <terra-icon size="16" [icon]="masteryStatus.panelOpen ? 'caret-up' : 'caret-down'" />
              </mat-select-trigger>
              <mat-option data-cy="filter-toolbar_mastery-status_all" [value]="MasteryFilterStatus.All">All</mat-option>
              <mat-option data-cy="filter-toolbar_mastery-status_mastered" [value]="MasteryFilterStatus.Mastered"
                >Mastered</mat-option
              >
              <mat-option data-cy="filter-toolbar_mastery-status_not-mastered" [value]="MasteryFilterStatus.NotMastered"
                >Not Mastered</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <terra-switch
          attr.data-cy="filters-toolbar_archived-toggle-{{ data.showArchived }}"
          *ngIf="data.options?.archiveToggle"
          class="archive-toggle"
          [class._terra-migration-approved-override--filters-toolbar]="!data.terrafy"
          [disabled]="(spinnerService.primary$ | async) || data.isFetchingData"
          [ngModel]="data.showArchived"
          (change)="this.filterService.onToggleArchive($event)">
          Archive
        </terra-switch>

        <terra-switch
          attr.data-cy="filters-toolbar_complete-toggle-{{ data.showCompleted }}"
          *ngIf="data.options?.completeToggle"
          class="complete-toggle"
          [class._terra-migration-approved-override--filters-toolbar]="!data.terrafy"
          [disabled]="(spinnerService.primary$ | async) || data.isFetchingData"
          [ngModel]="data.showCompleted"
          (change)="this.filterService.onToggleComplete($event)">
          Completed
        </terra-switch>

        <div class="fill-remaining-space" fxHide.lt-md></div>

        <button
          *ngIf="data.options?.expandCollapse"
          mat-icon-button
          (click)="filterService.expandAll$.next(null)"
          matTooltip="Expand All"
          matTooltipClass="ninety-tooltip"
          aria-label="Expand All"
          [disabled]="data.isFetchingData">
          <terra-icon icon="caret-down" />
        </button>
        <button
          *ngIf="data.options?.expandCollapse"
          mat-icon-button
          (click)="filterService.collapseAll$.next(null)"
          matTooltip="Collapse All"
          matTooltipClass="ninety-tooltip"
          aria-label="Collapse All"
          [disabled]="data.isFetchingData">
          <terra-icon icon="caret-up" />
        </button>

        <div *ngIf="data.options?.zoomButtons" class="zoom-buttons">
          <button
            mat-icon-button
            (click)="filterService.zoomOut()"
            matTooltip="Zoom Out"
            matTooltipClass="ninety-tooltip"
            aria-label="Zoom Out"
            [disabled]="data.isFetchingData">
            <terra-icon icon="zoom-out" />
          </button>
          <mat-label id="zoom-label" aria-label="Zoom Scale"
            >{{ parsePercent(filterService.zoomScale$ | async) }}
          </mat-label>
          <button
            mat-icon-button
            (click)="filterService.zoomIn()"
            matTooltip="Zoom In"
            aria-label="Zoom In"
            [disabled]="data.isFetchingData">
            <terra-icon icon="zoom-in" />
          </button>
          <button
            mat-icon-button
            (click)="filterService.zoomReset()"
            matTooltip="Reset Zoom"
            matTooltipClass="ninety-tooltip"
            aria-label="Reset Zoom"
            [disabled]="data.isFetchingData">
            <terra-icon icon="zoom-reset" />
          </button>
        </div>

        <!-- todo - is helpful check needed?  helpful is always owner -->
        <div
          *ngIf="
            stateService.company.settings.customVto &&
            data.options?.vtoGrid &&
            data.isSlt &&
            (stateService.isManagerOrAbove || isHelpful$ | ngrxPush) &&
            !data.archivedVto
          "
          fxHide.lt-md>
          <ninety-grid-layout-toolbar-buttons
            [layoutModeActive]="data.options?.gridLayoutOptions"
            [layoutModeDisabled]="spinnerService.primary$ | async">
          </ninety-grid-layout-toolbar-buttons>
        </div>

        <!-- Edit Layout Actions -->
        <ninety-layout-actions-group
          *ngIf="data.options?.editLayoutBtn"
          data-cy="filters-toolbar_actions-button"
          [isFetching]="data.isFetchingData"></ninety-layout-actions-group>

        <!-- Refresh Button -->
        <button
          data-cy="filters-toolbar_refreshButton"
          *ngIf="data.options?.refreshButton"
          mat-icon-button
          data-cy="filters-toolbar_refresh-button"
          data-product="filters-toolbar__refresh-button"
          (click)="filterService.onRefresh()"
          [matTooltip]="'Refresh ' + (stateService.pageTitle$ | async)"
          matTooltipClass="ninety-tooltip"
          aria-label="Refresh"
          [disabled]="data.isFetchingData">
          <terra-icon icon="refresh" />
        </button>

        <!-- Quarterly Export Button -->
        <button
          *ngIf="data.options?.quarterlyExportBtn"
          mat-icon-button
          (click)="filterService.onDownloadQuarterlyExport()"
          matTooltip="Download Quarterly Export"
          matTooltipClass="ninety-tooltip"
          aria-label="Download Quarterly Export"
          [disabled]="data.isFetchingData || data.selectedTeam._id === 'all'">
          <terra-icon icon="data" />
        </button>

        <!-- Forecasting Scorecard Button -->
        <button
          *ngIf="data.options?.showForecastingBtn && stateService.isManagerOrAbove"
          mat-icon-button
          (click)="filterService.onGoalForecasting()"
          [disabled]="data.isFetchingData"
          data-product="filters-toolbar__goal-forecast-button"
          matTooltip="{{ data.language.measurable.goal }} Forecasting"
          matTooltipClass="ninety-tooltip"
          attr.aria-label="{{ data.language.measurable.goal }} Forecasting">
          <terra-icon icon="chart-bar" />
        </button>

        <!-- Print PDF Button -->
        <button
          data-cy="filters-toolbar_print-to-pdf-button"
          *ngIf="data.options?.printToPdfBtn"
          mat-icon-button
          (click)="filterService.onPrintPdf()"
          [disabled]="data.isFetchingData"
          class="pdf-btn"
          matTooltip="Print to PDF"
          matTooltipClass="ninety-tooltip"
          aria-label="Print to PDF">
          <terra-icon icon="file-pdf" />
        </button>

        <!-- Download Excel Button -->
        <button
          *ngIf="
            data.options?.excelExportType &&
            ((data.excelDownloadFeatureFlag &&
              (data.options?.excelExportType === ExcelExportType.teamTodo ||
                data.options?.excelExportType === ExcelExportType.teamHeadlines)) ||
              (data.excelDownloadBatch2FeatureFlag &&
                (data.options?.excelExportType === ExcelExportType.issues ||
                  data.options?.excelExportType === ExcelExportType.personalTodo ||
                  data.options?.excelExportType === ExcelExportType.rocksMilestones)))
          "
          mat-icon-button
          (click)="filterService.onCreateExcel(data.options.excelExportType)"
          [disabled]="data.isFetchingData"
          class="pdf-btn"
          matTooltip="Download Excel"
          matTooltipClass="ninety-tooltip"
          aria-label="Download Excel">
          <terra-icon icon="download" />
        </button>

        <!-- Download Button -->
        <button
          *ngIf="data.options?.downloadCsvBtn"
          mat-icon-button
          (click)="filterService.onDownloadCsv()"
          matTooltip="Download XLS"
          data-product="filters-toolbar__download-xls-button"
          matTooltipClass="ninety-tooltip"
          aria-label="Download XLS"
          [disabled]="data.isFetchingData">
          <terra-icon icon="download" />
        </button>

        <!-- Archive-All Button -->
        <button
          data-cy="filters-toolbar_archive-all-completed-button"
          *ngIf="data.options?.archiveAllBtn && !data.showArchived && stateService.isManagerOrAbove"
          mat-icon-button
          (click)="filterService.onArchiveCompleted()"
          [disabled]="data.isFetchingData"
          matTooltip="Archive all completed"
          matTooltipClass="ninety-tooltip"
          aria-label="Archive all completed">
          <terra-icon icon="archive" />
        </button>

        <!-- Search box -->
        <ng-container *ngIf="data.options?.searchInput">
          <ng-container *ngIf="data.terrafy; else legacySearch">
            <terra-search-input
              data-cy="filters-toolbar_search-input-field"
              class="terrafy-search"
              [ngModel]="filterService.searchText$ | async"
              (ngModelChange)="filterService.setSearchText($event)"
              [placeholder]="'Search ' + (stateService.pageTitle$ | async)"
              aria-label="search filter"
              [readonly]="data.isFetchingData" />
          </ng-container>
          <ng-template #legacySearch>
            <div class="search-input-container">
              <terra-icon icon="search" />
              <mat-form-field class="search-input" floatLabel="never">
                <input
                  data-cy="filters-toolbar_search-input-field"
                  #search
                  matInput
                  [placeholder]="'Search ' + (stateService.pageTitle$ | async)"
                  value=""
                  [value]="filterService.searchText$ | async"
                  (keyup)="filterService.setSearchText(search.value)"
                  name="ninetySearch"
                  type="search"
                  aria-label="search filter"
                  [readonly]="data.isFetchingData" />
                <button
                  data-cy="filters-toolbar_search-close-button"
                  mat-button
                  *ngIf="search.value"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  [disabled]="data.isFetchingData"
                  (click)="filterService.setSearchText('')">
                  <terra-icon icon="x" size="12" variant="bold" />
                </button>
              </mat-form-field>
            </div>
          </ng-template>
        </ng-container>

        <!-- Settings Button -->
        <button
          *ngIf="data.options?.settingsBtn && stateService.isManagerOrAbove"
          data-cy="filters-toolbar_settings-button"
          mat-icon-button
          (click)="filterService.onSettings()"
          matTooltipClass="ninety-tooltip"
          [matTooltip]="'Open ' + (stateService.pageTitle$ | async) + ' Settings'"
          aria-label="Open Settings"
          [disabled]="spinnerService.primary$ | async">
          <terra-icon icon="settings" />
        </button>

        <!-- Settings Button -->
        <button
          *ngIf="data.options?.feedbackSettingsBtn && stateService.isAdminOrOwner"
          mat-icon-button
          data-cy="filters-toolbar_settings-button"
          class="settings-btn"
          (click)="auxRouterService.closeDetailBeforeNavigate(activatedRoute, ['1-on-1/settings'])">
          <terra-icon
            icon="settings"
            [matTooltip]="'Open ' + data.language.feedback.route + ' Settings'"
            matTooltipPosition="above"
            matTooltipClass="ninety-tooltip" />
        </button>
      </div>
    </div>
  </mat-toolbar>
</ng-container>
