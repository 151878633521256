import { DataImportType } from '@ninety/data-import/models/data-import-types';
import { OneSchemaRoleName } from '@ninety/data-import/models/one-schema-role-name';
import { UserRecord } from '@ninety/data-import/models/records';

export const DataImportStateKey = 'data-import';

export interface DataImportModel {
  userJwt: string;
  selectedImportType: DataImportType;
  selectedTeamId: string;
  showOneSchemaIframe: boolean;
  showPreviewTable: boolean;
}

export const TEST_DATA: UserRecord[] = [
  {
    firstName: 'John',
    lastName: 'Wayne',
    email: 'johnwayne@webworm.io',
    role: OneSchemaRoleName.owner,
    team1: 'Leadership Team',
    team2: 'Team 2',
    team3: 'Team 3',
    team4: 'Team 4',
  },
  {
    firstName: 'Edward',
    lastName: 'Teach',
    email: 'blackbeard@webworm.io',
    role: OneSchemaRoleName.admin,
    team1: 'Leadership Team',
  },
  {
    email: 'peyton@webworm.io',
    lastName: 'Thompson',
    role: OneSchemaRoleName.managee,
    firstName: 'Peyton',
    team1: 'Marketing',
  },
  {
    firstName: '',
    email: '',
    lastName: '',
    role: null,
  },
  {
    email: 'ball-and-chain@webworm.io',
    lastName: 'Thompson',
    firstName: 'Kristin',
    role: OneSchemaRoleName.managee,
    team1: 'Leadership Team',
  },
  {
    lastName: 'Palmer',
    role: OneSchemaRoleName.managee,
    firstName: 'Arnold',
    email: 'arnie@webworm.io',
    team1: 'Leadership Team',
  },
  {
    email: 'captainjack@webworm.io',
    lastName: 'Sparrow',
    role: OneSchemaRoleName.managee,
    firstName: 'Jack',
    team1: 'Alcoholics Anonymous',
  },
  {
    firstName: 'Average',
    lastName: 'Joe',
    email: 'average@webworm.io',
    role: OneSchemaRoleName.manager,
    team1: 'Team 1',
    team2: 'Team 2',
    team3: 'Team 3',
    team4: 'Team 4',
  },
  {
    role: OneSchemaRoleName.coach,
    lastName: 'Smart',
    email: 'kirby@webworm.io',
    firstName: 'Kirby',
    team1: 'Leadership Team',
  },
  {
    role: OneSchemaRoleName.coach,
    lastName: 'Jones',
    firstName: 'Potato',
    email: 'potato@webworm.io',
    team1: 'Potato Heads',
  },
];

// going straight to user preview table
export const initialDataImportState: DataImportModel = {
  userJwt: '',
  selectedImportType: null,
  selectedTeamId: null,
  showOneSchemaIframe: false,
  showPreviewTable: false,
};
