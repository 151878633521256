<div *ngIf="editing; else plainText" class="inline-editable-input">
  <input
    #input
    (keydown.enter)="toggleEdit()"
    (ngModelChange)="writeValue($event)"
    [class.no-value]="!_value"
    [ngModel]="_value"
    [placeholder]="placeholder"
    autocomplete="new-password"
    type="text" />
  <ninety-button (click)="toggleEdit()" *ngIf="_value?.length" class="terra-input__suffix" compact flat icon primary>
    <terra-icon icon="check" size="20" />
  </ninety-button>
</div>

<ng-template #plainText>
  <div>{{ _value }}</div>
  <ninety-button (click)="toggleEdit()" class="edit-btn" compact flat icon ninetyTooltip="Edit">
    <terra-icon icon="edit" size="20" />
  </ninety-button>
</ng-template>
