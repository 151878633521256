import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { format } from 'date-fns';
import { BehaviorSubject } from 'rxjs';

import { TerraIconModule } from '@ninety/terra';
import { MaterialMdcModule } from '@ninety/ui/legacy/angular-material/material-mdc.module';
import { ButtonComponent } from '@ninety/ui/legacy/components/buttons/button/button.component';
import { QuickFilterComponent } from '@ninety/ui/legacy/components/inputs/quick-filter/quick-filter.component';
import { MeetingType } from '@ninety/ui/legacy/shared/index';
import { selectCurrentUserId, selectLanguage, TeamSelectors } from '@ninety/ui/legacy/state/index';

import { MeetingSchedulingActions } from '../../../_state/meetings.actions';
import { MeetingsStateSelectors } from '../../../_state/meetings.selectors';
import { Cadence } from '../../../models/cadence.enum';

@Component({
  selector: 'ninety-schedule-meeting-dialog',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TerraIconModule,
    LetDirective,
    ButtonComponent,
    MatDialogModule,
    QuickFilterComponent,
    MaterialMdcModule,
  ],
  templateUrl: './schedule-meeting-dialog.component.html',
  styleUrls: ['./schedule-meeting-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleMeetingDialogComponent implements OnInit {
  private store = inject(Store);
  private dialogRef = inject(MatDialogRef<ScheduleMeetingDialogComponent>);

  form: FormGroup;
  protected cadence$ = new BehaviorSubject<{ name: string; value: Cadence }[]>([...this.getCadenceOptions(new Date())]);
  minDate = new Date();

  vm$ = {
    language: this.store.select(selectLanguage),
    agendas: this.store.select(MeetingsStateSelectors.selectCombinedAgendaOptions),
    currentUserId: this.store.select(selectCurrentUserId),
    team: this.store.select(MeetingsStateSelectors.selectTeamSelectList),
    filterBarTeam$: this.store.select(TeamSelectors.selectFilterBarTeam),
  };

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      agenda: ['', Validators.required],
      date: [new Date(), Validators.required],
      hours: ['', Validators.required],
      minutes: ['', Validators.required],
      period: ['am', Validators.required],
      cadence: [Cadence.none, Validators.required],
      presenterId: ['', Validators.required],
    });
  }

  close() {
    this.form.reset();
    this.dialogRef.close();
  }

  dateChanged(date: Date) {
    this.cadence$.next(this.getCadenceOptions(date));

    this.form.patchValue({ cadence: Cadence.none });
  }

  save() {
    if (this.form.valid) {
      this.store.dispatch(MeetingSchedulingActions.addMeetingSchedule(this.formToAddMeetingModel()));

      this.close();
    }
  }

  formToAddMeetingModel() {
    const { agenda, date, cadence, hours, minutes, period, presenterId } = this.form.value;
    const meetingType = agenda.item ? MeetingType.custom : agenda.id;
    const agendaId = agenda.item ? agenda.item._id : null;

    const dateWithoutTime = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    const adjustedHours = period === 'pm' ? 1 * hours + 12 : 1 * hours;
    const dateTime = new Date(dateWithoutTime.setHours(adjustedHours, minutes));

    return {
      schedule: {
        meetingType,
        agendaId,
        presenterId,
        cadence,
        cadenceStartDate: dateTime,
        parentId: null,
      },
    };
  }

  getCadenceOptions(date: Date): { name: string; value: Cadence }[] {
    return [
      { name: 'does not repeat', value: Cadence.none },
      { name: `Weekly on ${format(date, 'EEEE')}`, value: Cadence.weekly },
      { name: `Monthly on the ${this.getNthDayInTheMonth(date)} ${format(date, 'EEEE')}`, value: Cadence.monthly },
    ];
  }

  getNthDayInTheMonth(date: Date): string {
    const day = date.getDate();
    const ordinals = ['first', 'second', 'third', 'fourth', 'fifth'];
    return ordinals[Math.floor((day - 1) / 7)];
  }
}
