import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, exhaustMap, filter, map, of, switchMap, tap } from 'rxjs';

import { DataImportActions } from '@ninety/data-import/_state/data-import.actions';
import { DataImportSelectors } from '@ninety/data-import/_state/data-import.selectors';
import { UserImportConfirmDialogComponent } from '@ninety/data-import/dialogs/user-import-confirm-dialog/user-import-confirm-dialog.component';
import { OneSchemaRoleName, OneSchemaRoleNameAsCode } from '@ninety/data-import/models/one-schema-role-name';
import { OneSchemaTemplateKey } from '@ninety/data-import/models/one-schema-template-key';
import { UserRecord } from '@ninety/data-import/models/records/user-record';
import { DataImportService } from '@ninety/data-import/services/data-import.service';
import { UserImportFormService } from '@ninety/data-import/services/user-import-form.service';
import { ConfirmDialogComponent } from '@ninety/ui/legacy/shared/components/_mdc-migration/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogData } from '@ninety/ui/legacy/shared/components/_mdc-migration/confirm-dialog/models';
import { WarningConfirmDialogComponent } from '@ninety/ui/legacy/shared/components/_mdc-migration/confirm-dialog/warning-confirm-dialog.component';
import { RoleCode } from '@ninety/ui/legacy/shared/models/_shared/role-code';
import { InviteUserPayload } from '@ninety/ui/legacy/shared/models/directory/invite-user-payload';
import { DataImportItemType } from '@ninety/ui/legacy/shared/models/enums/data-import-item-type';
import { selectRoleSelectData } from '@ninety/ui/legacy/state/app-entities/roles/roles-state.selectors';
import { TeamSelectors } from '@ninety/ui/legacy/state/app-entities/team-list/team-list-state.selectors';
import { selectAllUsers } from '@ninety/ui/legacy/state/app-entities/users/users-state.selectors';
import { BillingStateActions } from '@ninety/ui/legacy/state/app-global/billing/billing-state.actions';
import { selectCompanyBillingCounts } from '@ninety/ui/legacy/state/app-global/billing/billing-state.selectors';
import { selectCompany, selectCompanyId } from '@ninety/ui/legacy/state/app-global/company/company-state.selectors';
import { selectHasHelpfulDataImportPermissions } from '@ninety/ui/legacy/state/app-global/helpful-permissions/helpful-permissions.selectors';
import { NotificationActions } from '@ninety/ui/legacy/state/app-global/notifications/notification.actions';

@Injectable()
export class UserImportEffects {
  /** if not helpful and importing users, show users import confirm dialog */
  whenImportingUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.setInitialImportType),
      concatLatestFrom(() => [
        this.store.select(selectHasHelpfulDataImportPermissions),
        this.store.select(DataImportSelectors.selectTemplateKey),
      ]),
      filter(([_, isHelpful, templateKey]) => !isHelpful && templateKey === OneSchemaTemplateKey.users),
      map(() => DataImportActions.showUsersImportConfirmDialog())
    )
  );
  handleUsersFromOneSchema$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.handleDataFromOneSchema),
      filter(({ data }) => data.template_key === OneSchemaTemplateKey.users),
      concatLatestFrom(() => this.store.select(selectHasHelpfulDataImportPermissions)),
      map(([{ data }, isHelpful]) => {
        if (isHelpful) return DataImportActions.helpfulPreviewBypass({ users: data.records });
        return DataImportActions.buildImportUserFormArray({ records: data.records });
      })
    )
  );

  showUsersImportConfirmDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.showUsersImportConfirmDialog),
      exhaustMap(() =>
        this.dialog
          .open<UserImportConfirmDialogComponent>(UserImportConfirmDialogComponent, {
            autoFocus: false,
            disableClose: true,
            width: '460px',
          })
          .afterClosed()
      ),
      filter(proceed => !!proceed),
      map(() => DataImportActions.confirmedReadyToProceed())
    )
  );

  getBillingCountsWhenImportingUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.selectImportType),
      /** if we implement deep linking to a particular template, we need to make sure this fires */
      filter(({ option }) => option.importType === DataImportItemType.user),
      map(() => BillingStateActions.getCompanyBillingCounts())
    )
  );

  buildImportUserFormArray$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.buildImportUserFormArray),
      concatLatestFrom(() => [
        this.store.select(TeamSelectors.selectAll),
        this.store.select(selectRoleSelectData),
        this.store.select(selectCompanyId),
      ]),
      map(([{ records }, allTeams, roles, companyId]) => {
        const users = records.map(user => {
          const { team1, team2, team3, team4, team5 } = user;
          const selectedTeams = [];
          const nonMatchingTeamNames = [];
          [team1, team2, team3, team4, team5].forEach(teamName => {
            if (!teamName) return;
            const team = allTeams.find(t => t.name.toLowerCase() === teamName.toLowerCase());
            if (team) selectedTeams.push(team);
            else nonMatchingTeamNames.push(teamName);
          });
          const roleCode = OneSchemaRoleNameAsCode[user.role] || RoleCode.managee;
          const isImplementer = user.role === OneSchemaRoleName.coach;
          return {
            ...user,
            role: roles.find(
              r =>
                r.roleCode === roleCode &&
                // must account for coaches roleCode: owner and isImplementer: true
                r.isImplementer === isImplementer
            ),
            selectedTeams,

            //hidden controls
            teams: selectedTeams.map(({ _id: teamId }) => ({ teamId })),
            roleCode,
            isImplementer,
            companyId,
            hasBeenInvited: false,
            active: false,
            nonMatchingTeamNames, // to display in the UI in case they misspelled the team doesn't exist
          };
        });
        this.userImportFormService.buildImportUserFormArray(users);
        return DataImportActions.showUsersPreviewTable();
      })
    )
  );

  openCancelConfirmDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.openCancelConfirmDialog),
      switchMap(() =>
        this.dialog
          .open<WarningConfirmDialogComponent, ConfirmDialogData>(WarningConfirmDialogComponent, {
            data: {
              title: 'Cancel User Import',
              message: 'Canceling this import will terminate the process and take you to the starting point.',
              confirmButtonText: 'Cancel import',
              cancelButtonText: `Don't cancel`,
            },
          })
          .afterClosed()
          .pipe(
            filter(confirmed => !!confirmed),
            map(() => DataImportActions.cancel())
          )
      )
    )
  );

  deleteUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DataImportActions.deleteUser),
        switchMap(({ index }) =>
          this.dialog
            .open<WarningConfirmDialogComponent, ConfirmDialogData>(WarningConfirmDialogComponent, {
              data: {
                title: 'Delete user',
                message: 'Are you sure you want to delete this user?',
                confirmButtonText: 'Delete user',
                confirmButtonIcon: 'delete',
              },
            })
            .afterClosed()
            .pipe(
              filter(confirmed => !!confirmed),
              tap(() => this.userImportFormService.deleteUser(index))
            )
        )
      ),
    { dispatch: false }
  );

  createUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.createUsers),
      map(() => this.userImportFormService.getUsersFromForm()),
      switchMap(users =>
        this.dataImportService.createUsers(users).pipe(
          /** todo implement new snackbar */
          map(() => DataImportActions.success({ message: `Successfully imported users` })),
          catchError((error: unknown) => of(DataImportActions.error({ error })))
        )
      )
    )
  );
  /** Billing Checks */

  checkNumberOfLicenses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.checkNumberOfLicenses),
      concatLatestFrom(() => this.store.select(selectCompanyBillingCounts)),
      map(([{ users }, billingCounts]) => {
        if (users.length <= billingCounts.assignableSeats) return DataImportActions.helpfulCreateUsers({ users });
        return DataImportActions.openLicenseChangeConfirmDialog({ users, billingCounts });
      })
    )
  );

  /** temporary to maintain current behavior before preview table is complete */
  helpfulImportUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.helpfulPreviewBypass),
      concatLatestFrom(() => [
        this.store.select(selectAllUsers),
        this.store.select(selectCompany),
        this.store.select(TeamSelectors.selectAll),
      ]),
      map(([{ users }, currentUsers, company, allTeams]) => {
        const usersToImport: InviteUserPayload[] = UserRecord.toInviteUsersPayload(users, company, allTeams);
        const currentUserEmails: string[] = currentUsers.map(u => u.emailAddresses[0]?.email);

        const alreadyAddedEmails = usersToImport.filter(u => currentUserEmails.includes(u.email)).map(u => u?.email);

        if (!alreadyAddedEmails.length) return DataImportActions.checkNumberOfLicenses({ users: usersToImport });

        return NotificationActions.notifyV2({
          message: `Users already found in company with the following email addresses: ${alreadyAddedEmails.join(
            ', '
          )}`,
          params: { config: { duration: 10000 } },
        });
      })
    )
  );
  openLicenseChangeConfirmDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.openLicenseChangeConfirmDialog),
      switchMap(({ users, billingCounts }) => {
        const seatOrSeats = billingCounts.assignableSeats > 1 ? 'seats' : 'seat';
        const availableSeatSentence = billingCounts.assignableSeats
          ? `, but you only have <strong>${billingCounts.assignableSeats} ${seatOrSeats} available</strong>.`
          : '.';

        return this.dialog
          .open<ConfirmDialogComponent, ConfirmDialogData>(ConfirmDialogComponent, {
            data: {
              title: 'License change required',
              message: `You are about to import <strong>${users.length} users</strong>${availableSeatSentence}
You will be charged for an additional <strong>${users.length - billingCounts.assignableSeats} seats</strong>.`,
              confirmButtonText: 'Confirm ',
            },
          })
          .afterClosed()
          .pipe(
            filter(result => result),
            map(() => DataImportActions.helpfulCreateUsers({ users }))
          );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private dataImportService: DataImportService,
    private dialog: MatDialog,
    private userImportFormService: UserImportFormService
  ) {}
}
