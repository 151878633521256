<terra-icon
  (mouseenter)="onMouseEnter()"
  (mouseleave)="onMouseLeave()"
  #errorOverlayTrigger="ninetyPopoverOrigin"
  [icon]="errorIcon"
  [size]="iconSize"
  class="error-overlay-icon"
  ninetyPopoverOrigin />

<ninety-popover
  (popoverClosed)="hide()"
  [content]="errorOverlay"
  [isOpen]="showOverlay"
  [trigger]="errorOverlayTrigger"></ninety-popover>

<ng-template #errorOverlay>
  <div class="error-overlay">
    <div class="overlay-header">
      <div class="overlay-title">{{ overlayTitle }}</div>
    </div>
    <terra-divider />
    <div class="overlay-body">
      <div class="error-title">{{ errorTitle }}</div>
      <div class="error-description">
        <ul *ngIf="errorDescription; else errorContent">
          <li>{{ errorDescription }}</li>
        </ul>
        <ng-template #errorContent>
          <ng-content select="[errorContent]"></ng-content>
        </ng-template>
      </div>
      <ng-container>
        <div #errorActions class="actions-container">
          <ng-content select="[errorActions]"></ng-content>
        </div>
        <div *ngIf="!errorActions?.children?.length && !hideActionButton" class="error-actions">
          <ninety-button (click)="acknowledge()" primary stroked>{{ actionButtonLabel }}</ninety-button>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
